import React from 'react';
import Box from '@material-ui/core/Box';
import Gallery from "react-photo-gallery";
import Layout from "../components/Layout";
import SiteHelmet from "../components/SiteHelmet";
import {graphql, useStaticQuery} from "gatsby";

const Drawings = () => {
  const data = useStaticQuery(
    graphql`
        query {
            allDrawingsJson {
                edges {
                    node {
                        src
                        width
                        height
                    }
                }
            }
        }
    `,
  );

  const pictures = data.allDrawingsJson.edges.map(pic => {
    return pic.node
  });

  return (
    <Layout>
      <SiteHelmet title="Drawings"/>
      <Box>
        <Gallery photos={pictures} direction={"column"} />
      </Box>
    </Layout>
  );
};

export default Drawings;